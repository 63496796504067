import React from "react";
import { ScrollView } from "react-native";
import { View, Text } from 'native-base';
import { Card } from "react-native-elements";
import { IconButton } from "react-native-paper";
import { RFValue } from "react-native-responsive-fontsize";
import { useRoute } from "@react-navigation/native";

import { CommonDataTable } from "../../components/CommonDataTable";
import { LinkButton } from "../../components/LinkButton";
import { Loading } from "../../components/Loading";
import { OrderStatus } from "../../components/OrderStatus";
import { SubOrdersPerIndustry } from "../SubOrdersPerIndustry";
import { useRetailOrder } from "@hooks/useRetailOrder";
import { styles } from "./styles"

export const OrderDetail = () => {
  const route = useRoute();
  const { data, loading } = useRetailOrder(
    route.params?.orderId
  );

  const getOrderStatus = (status: any) => {
    return <OrderStatus status={status} />;
  };

  let industryOrders = [];
  let industryProducts: any[] = [];
  let orderId = "";

  const createRetailCard = (order: any) => {
    const retailProducts = order.products.map(function (product: any) {
      return {
        ean: [product.ean, 33],
        name: [product.name, 33],
        quantity: [product.quantity, 33],
      };
    });
    return (
      <Card key={String(Math.random())}>
        <View>
          {CommonDataTable(
            [["Id"], ["Data de Criação"], ["Status"]],
            [
              {
                id: [order.customerOrderCode, 33],
                data: [order.createdAt, 33],
                status: [getOrderStatus(order.status), 33]
              },
            ]
          )}
          {CommonDataTable([["EAN"], ["Nome"], ["Quantidade"]], retailProducts)}
        </View>
      </Card>
    );
  };

  if (data) {
    industryOrders = data.Retail_retailOrder.industryOrders;
    industryProducts = data.Retail_retailOrder.products;
    orderId = data.Retail_retailOrder.customerOrderCode;
  }

  return (
    <ScrollView style={styles.cardContainer}>
      <Card>
        <View style={styles.containerContentHeader}>
          <Text style={styles.textTitleContentHeader}>
            <LinkButton
              style={[]}
              to={{
                screen: "ListOrder",
              }}
            >
              <IconButton
                icon={"arrow-left"}
                color="#74BF48"
                size={RFValue(9)}
                rippleColor="rgba(229, 229, 229, 0.5)"
              />
            </LinkButton>
            Resumo do Pedido #{orderId}
          </Text>
        </View>
        {loading ? (
          <Loading />
        ) : industryOrders.length > 0 ? (
          industryOrders.map((ind: any) =>
            ind.industryWholesalerOrders.length > 0 ? (
              <SubOrdersPerIndustry
                key={String(Math.random()) + ind.industryCode}
                industry={ind.industryCode}
                subOrderRows={ind}
                products={industryProducts}
              />
            ) : (
              createRetailCard(data.Retail_retailOrder)
            )
          )
        ) : (
          createRetailCard(data.Retail_retailOrder)
        )}
      </Card>
    </ScrollView>
  );
};