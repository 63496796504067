import React, { useState, useRef, useEffect } from "react";
import { RefreshControl } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  useToast,
  Text,
  View,
  Pressable,
  Icon,
  useMediaQuery,
  Button,
  Box,
  ScrollView,
  HStack,
  VStack,
} from "native-base";

import Alert, { useRefAlert, Props as AlertProps } from "@components/Alert";
import { AnchorProducts } from "@components/AnchorProducts";
import { DialogContentMinAmount } from "@components/Alert/templates/DialogContentMinAmount";
import { EmptyCart } from "@components/EmptyCart";
import { LayoutOrder } from "@components/LayoutOrder";
import { LinkButton } from "@components/LinkButton";
import { ModalCoupon } from "@components/ModalCoupon";
import { ProductSummary } from "@components/ProductSummary";
import { SummarySuggestionListOrder } from "@components/SuggestedProducts/SummaryOrder";

import { Config } from "@configs/Config";
import { useAuth } from "@contexts/Auth";
import { useCoupon } from "@hooks/useCoupon";
import { useShoppingCart } from "@hooks/useShoppingCart";
import helpers from "@helpers/NumberHelper";
import { CouponsType } from "@types/CouponsType";
import { useCouponModal } from "@hooks/useCouponModal";
import { useCouponRulesModal } from "@hooks/useCouponRulesModal";
import { useAlertCouponModal } from "@hooks/useAlertCouponModal";
import {
  filterAggregatorProducts,
  filterListProducts,
  splitProducts,
} from "@helpers/SplitProductsByType";

import {
  useAddCouponResponsiveOverlayError
} from '@hooks/useAddCouponResponsiveOverlayError';
import { AddCouponResponsiveOverlayError } from '@components/AddCouponResponsiveOverlayError';
import { useAdditionalCustomers } from '@hooks/useAdditionalCustomers';
import { useTimeBlock } from "@hooks/useTimeBlock";
import { useWholesalers } from "@hooks/useWholesalers";

export const SummaryOrder = () => {
  useTimeBlock();
  const [isTimeModal, setIsTimeModal] = useState(true);
  const navigation = useNavigation();
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const { validateCart, cart, loading } = useShoppingCart();
  const { wholesalers, loading: wholeLoading } = useWholesalers();
  const { loadDiscounts } = useCoupon();
  const toast = useToast();
  const couponModal = useCouponModal()
  const couponRulesModal = useCouponRulesModal()
  const alertCouponModal = useAlertCouponModal()
  const addCouponError = useAddCouponResponsiveOverlayError();
  const [isLargeScreen] = useMediaQuery([{ minWidth: 620 }]);
  const alertDialog = useRef<useRefAlert>();
  const validCart = cart?.valid;
  const { coupons, unapplyCoupon, unapplyAllCoupons } = useCoupon();
  const appliedCoupons: CouponsType[] = coupons.filter(
    (coupon) => coupon.applied
  );
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const { getAdditionalCustomers } = useAdditionalCustomers();

  const params: AlertProps = {
    type: "ALERT",
    headerText: "Atenção!",
    bodyContent: DialogContentMinAmount(),
    btnTextConfirm: "Continuar comprando",

    showBtnCancel: false,
  };

  useEffect(() => {
    if (!isFirstLoading || !cart) return;
    validateCart(cart?.id as string)

    setIsFirstLoading(false)
  });

  async function handleRemoveAllCoupons() {
    await unapplyAllCoupons();
    handleShowToast("Cupons removidos com sucesso!");
  }

  async function toUnapplyCoupon(coupon: CouponsType) {
    await unapplyCoupon(coupon);
    handleShowToast(`Cupom ${coupon.coupon.name} foi removido`);
  }

  function handleShowToast(description: string) {
    toast.show({
      description,
      _description: {
        color: "muted.800",
        fontSize: ["xs", "sm"],
        p: 3,
      },
      placement: "top-right",
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "muted.300",
      duration: 3000,
      top: [6, 24],
      right: [6, 24],
      rounded: "sm",
    });
  }

  function onClickModalRules(item: CouponsType) {
    couponRulesModal.setCoupon(item);
    couponRulesModal.onOpen();
  }

  const addCoupons = () => {
    if (isTimeModal) {
      setIsTimeModal(false);

      if (isLargeScreen) {
        couponModal.onOpen();
        return loadDiscounts();
      }
      navigation.navigate("CouponsApply");
      loadDiscounts();
    }

    setTimeout(function () {
      setIsTimeModal(true);
    }, 500);
  };

  async function handleAddCouponClick() {
    if (!Config.FT_ADDITIONAL_CUSTOMERS) {
      addCoupons();
      return;
    };

    const additionalCustomers = await getAdditionalCustomers(cart?.id);
    if (additionalCustomers.length === 0) {
      addCoupons();
      return;
    }

    addCouponError.onOpen();
  }

  useEffect(() => {
    alertCouponModal.onClose();
  }, []);

  return (
    <View flex={1}>
      <ScrollView
        showsVerticalScrollIndicator={isLargeScreen}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={() => { }}
          />
        }
      >
        <LayoutOrder
          title="Resumo do pedido"
          subtitle={cart?.items.length !== 0 ? "Limpar carrinho" : undefined}
          isMobile={!isLargeScreen}
          shopTab={1}
          industryName={industry}
        >
          <Box flexDir={["column", "column", "row"]} shadow="none">
            <Alert ref={alertDialog} {...params} />

            {cart?.items.length !== 0 ? (
              // TODO: SWITCH IT TO A SECTION LIST
              <VStack
                w={["full", "full", "50%"]}
                mr={[0, 8]}
                mb={[6, 0]}
                px={[4, 8, 0]}
              >
                {filterAggregatorProducts()?.map((item: any) => {
                  return (
                    <Box key={item.product.code + item.amount} mb={[4, 6]}>
                      <ProductSummary product={item} />
                    </Box>
                  );
                })}
                {
                  <SummarySuggestionListOrder
                    products={filterListProducts() || []}
                  />
                }
              </VStack>
            ) : (
              <View
                flex={1}
                bg={["transparent", "white"]}
                rounded={["none", "sm"]}
                shadow={["none", 2]}
                py={24}
                mr={[0, 8]}
              >
                <EmptyCart industryName={industry} />
              </View>
            )}

            {cart?.items.length !== 0 && (
              <Box flex={1}>
                {Config.FT_KUPONO_ENABLED && (
                  <View
                    bg="white"
                    borderRadius={4}
                    mb={[0, 6]}
                    p={[4, 6]}
                    shadow={2}
                    pointerEvents={cart?.items.length === 0 ? "none" : "auto"}
                    opacity={cart?.items.length === 0 ? 0.4 : 1}
                    _dark={{
                      bg: "muted.800",
                    }}
                  >
                    <View
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={appliedCoupons.length === 0 ? 2 : 6}
                    >
                      <Text
                        fontSize={["md", "lg"]}
                        fontWeight="500"
                        color="muted.800"
                        _dark={{
                          color: "muted.400",
                        }}
                      >
                        Cupons de desconto
                      </Text>

                      {appliedCoupons.length >= 1 && (
                        <Pressable onPress={() => handleRemoveAllCoupons()}>
                          <Text
                            fontSize={["sm", "md"]}
                            fontWeight="500"
                            color="primary.600"
                            _dark={{
                              color: "primary.500",
                            }}
                          >
                            Excluir todos
                          </Text>
                        </Pressable>
                      )}
                    </View>

                    {appliedCoupons.length >= 1 && (
                      <>
                        {appliedCoupons.map((item) => {
                          return (
                            <View key={item.id} mb={6}>
                              <HStack
                                p={4}
                                rounded="sm"
                                alignItems="center"
                                justifyContent="space-between"
                                bgColor="muted.200"
                                _dark={{
                                  bg: "muted.700",
                                }}
                              >
                                <View flexDir="column">
                                  <Text
                                    fontSize={["xs"]}
                                    fontWeight="500"
                                    color="muted.800"
                                    _dark={{
                                      color: "muted.300",
                                    }}
                                  >
                                    {item.coupon.name}
                                  </Text>

                                  <Text
                                    fontSize={["xs"]}
                                    fontWeight="500"
                                    color="muted.700"
                                    _dark={{
                                      color: "muted.400",
                                    }}
                                  >
                                    Cupom aplicado
                                  </Text>
                                </View>

                                <Pressable
                                  onPress={() => onClickModalRules(item)}
                                >
                                  <Text
                                    color="primary.600"
                                    fontWeight="500"
                                    fontSize="sm"
                                    _dark={{
                                      color: "primary.500",
                                    }}
                                  >
                                    Ver regras
                                  </Text>
                                </Pressable>
                              </HStack>

                              <Pressable
                                mt={2}
                                onPress={() => toUnapplyCoupon(item)}
                              >
                                <Text
                                  color="primary.600"
                                  fontWeight="500"
                                  fontSize="sm"
                                  _dark={{
                                    color: "primary.500",
                                  }}
                                >
                                  Excluir cupom
                                </Text>
                              </Pressable>
                            </View>
                          );
                        })}
                      </>
                    )}

                    <Pressable
                      flexDir="row"
                      alignItems="center"
                      mt={appliedCoupons.length === 0 ? 0 : 6}
                      onPress={handleAddCouponClick}
                    >
                      <Icon
                        as={MaterialCommunityIcons}
                        size={8}
                        name="ticket-percent-outline"
                        color={"primary.600"}
                        mr={2}
                      />

                      <Text
                        fontSize={["md"]}
                        fontWeight="500"
                        color="primary.600"
                        _dark={{
                          color: "primary.500",
                        }}
                      >
                        Adicione seu cupom e aproveite!
                      </Text>
                    </Pressable>
                  </View>
                )}

                <View
                  bg="white"
                  borderRadius={4}
                  p={[4, 6]}
                  shadow={2}
                  pointerEvents={cart?.items.length === 0 ? "none" : "auto"}
                  opacity={cart?.items.length === 0 ? 0.4 : 1}
                  _dark={{
                    bg: "muted.800",
                  }}
                >
                  <Text
                    fontSize={["md", "lg"]}
                    fontWeight="500"
                    color="muted.800"
                    borderBottomWidth={0.25}
                    borderColor="muted.400"
                    pb={[4, 6]}
                    mb={4}
                    _dark={{
                      color: "muted.400",
                    }}
                  >
                    Resumo da compra
                  </Text>

                  {cart && cart?.items.length >= 0 ? (
                    <>
                      <View
                        borderBottomWidth={0.25}
                        borderColor="muted.400"
                        pb={4}
                        mb={4}
                      >
                        <View flexDir="row" justifyContent="space-between">
                          <Text
                            fontSize={["sm", "md"]}
                            fontWeight="500"
                            color="muted.700"
                            _dark={{
                              color: "muted.300",
                            }}
                          >
                            Subtotal
                          </Text>

                          <Text
                            fontSize={["sm", "md"]}
                            fontWeight="500"
                            color="muted.700"
                            _dark={{
                              color: "muted.300",
                            }}
                          >
                            R$ {helpers.currency(cart.grossPrice, 2)}
                          </Text>
                        </View>

                        <View
                          flexDir="row"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Text
                            fontSize={["sm", "md"]}
                            fontWeight="500"
                            color="orange.500"
                            _dark={{
                              color: "orange.300",
                            }}
                          >
                            Desconto
                          </Text>

                          <Text
                            fontSize={["sm", "md"]}
                            fontWeight="500"
                            color="orange.500"
                            _dark={{
                              color: "orange.300",
                            }}
                          >
                            - R$ {helpers.currency(cart.discountValue, 2)}
                          </Text>
                        </View>
                      </View>

                      <View flexDir="row" justifyContent="space-between" mb={6}>
                        <Text
                          fontSize={["md", "lg"]}
                          fontWeight="500"
                          color="muted.800"
                          _dark={{
                            color: "muted.400",
                          }}
                        >
                          Valor Total
                        </Text>

                        <Text
                          fontSize={["md", "lg"]}
                          fontWeight="500"
                          color="muted.800"
                          _dark={{
                            color: "muted.400",
                          }}
                        >
                          R$ {helpers.currency(cart.netPrice, 2)}
                        </Text>
                      </View>

                      <View w="100%">
                        <Button
                          _text={{
                            fontWeight: "700",
                            fontSize: ["md", "lg"],
                          }}
                          _hover={{
                            bg: "primary.500",
                          }}
                          _pressed={{
                            bg: "primary.400",
                          }}
                          _dark={{
                            opacity: 0.8,
                          }}
                          testID="resumo-pedido:proximo-passo"
                          bg="primary.600"
                          mb={2}
                          rounded="sm"
                          onPress={() => {
                            if (!validCart) {
                              alertDialog.current?.show();
                              return;
                            }
                            navigation.navigate("WholesalerOrder", {
                              industry: industry,
                            });
                          }}
                        >
                          Próximo passo
                        </Button>

                        <LinkButton
                          to={{
                            screen: "Industry",
                            params: { industry: industry },
                          }}
                          testID="resumo-pedido:continuar-comprando"
                          w="100%"
                          p={2}
                          alignItems="center"
                          justifyContent="center"
                          _hover={{
                            opacity: 0.8,
                          }}
                          _pressed={{
                            bgColor: "muted.300",
                          }}
                          _dark={{
                            _pressed: {
                              bgColor: "muted.700",
                            },
                          }}
                        >
                          <Text
                            fontSize={["md", "lg"]}
                            fontWeight="700"
                            color="primary.600"
                            _dark={{
                              color: "primary.500",
                            }}
                          >
                            Continuar comprando
                          </Text>
                        </LinkButton>
                      </View>
                    </>
                  ) : (
                    <Text
                      fontSize={["sm", "md"]}
                      fontWeight="500"
                      color="muted.700"
                    >
                      Aqui, você encontra os valores da sua compra, assim que
                      adicionar produtos.
                    </Text>
                  )}
                </View>
                {!wholeLoading && 
                  <AnchorProducts wholesalerCode={wholesalers[0]?.code || ""}/>
                }
              </Box>
            )}
          </Box>
        </LayoutOrder>

        {isLargeScreen && <ModalCoupon />}

        {Config.FT_ADDITIONAL_CUSTOMERS && (<AddCouponResponsiveOverlayError type={isLargeScreen ? 'WEB' : 'MOBILE'} />)}
      </ScrollView>
    </View>
  );
};
