import React, { useEffect, useRef, useState } from 'react';
import { FlatList, ScrollView } from 'react-native';
import { View, Text } from 'native-base';

import { Loading } from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { UnexpectedError } from '@components/UnexpectedError';
import { HeaderOrderGrid, OrderGridItem } from '../OrderGridItem';

import { Config } from '@configs/Config';
import { useRetailOrders } from '@hooks/useRetailOrders';
import { OrderType } from '@types/OrderType';


import { styles } from './styles';

const totalOrderPerPage =
  Config.TOTAL_ORDER_EXHIBITED ? parseInt(Config.TOTAL_ORDER_EXHIBITED) : 20;

export const ListOrder = () => {
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [focusItemList, setFocusItemList] = useState<OrderType[]>();
  const [orderList, setOrderList] = useState<OrderType[]>([]);

  const myRef = useRef<null | HTMLDivElement>(null);
  const executeScroll = () => myRef.current?.scrollIntoView();

  const { data, loading, error, refetch } = useRetailOrders(
    totalOrderPerPage,
    0
  );

  if (data && orderList.length === 0 && data.Retail_retailOrders.total !== 0) {
    setTotal(data.Retail_retailOrders.total);
    const result = data.Retail_retailOrders.data;
    const startItemList = result.slice(0, totalOrderPerPage);
    setFocusItemList(startItemList);
    setOrderList(result);
  }

  const updateData = async (newOffset: number = 0) => {
    const result = await refetch({
      page: newOffset,
    });

    setTotal(result.data.Retail_retailOrders.total);

    const startItemList = result.data.Retail_retailOrders.data;
    setFocusItemList(startItemList);
    setOrderList(startItemList);
    executeScroll();
  };

  const handleOffset = (page: number) => {
    setOffset(page + 1);
  };

  useEffect(() => {
    if (offset !== undefined) {
      updateData(offset);
    }
  }, [offset]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.containerBox}>
        <View style={styles.containerHeader}>
          <View>
            <Text style={styles.title}>Lista de Pedidos</Text>
            <Text style={styles.subtitle}>
              Aqui está o resumo dos seus pedidos, basta pesquisar.
            </Text>
          </View>
        </View>
        <View>
          {data.Retail_retailOrders.total !== 0 ? (
            <View>
              <FlatList
                data={focusItemList}
                renderItem={({ item }: { item: OrderType }) => (
                  <OrderGridItem item={item} />
                )}
                keyExtractor={item => item.id}
                ListHeaderComponent={HeaderOrderGrid}
                initialNumToRender={20}
                style={{ borderWidth: 1, borderBottomWidth: 0 }}
              />

              <Pagination
                pageCount={totalOrderPerPage}
                stylePaginationButton={styles.paginationButton}
                stylePaginationButtonActive={styles.paginationButtonActive}
                itemList={orderList}
                setOffset={handleOffset}
                isDefault={false}
                total={total}
              />
            </View>
          ) : (
            <View>
              <Text style={styles.textDescription}>
                Não há dados a serem exibidos
              </Text>
            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
};
