import { useQuery, gql } from "@apollo/client";

const RETAIL_ORDER = gql`
  query ($id: String!) {
    Retail_retailOrder(id: $id) {
      id
      createdAt
      customerCode
      customerOrderCode
      source
      status
      products {
        name
        ean
        quantity
        invoiceQuantity
        industryCode
        discountPercentage
        unitNetPrice
        extraDiscounts {
          couponName
          discount
        }
      }
      industryOrders {
        industryCode
        industryWholesalerOrders {
          wholesalerBranch {
            id
            name
            code
          }
          tradetoolsSubOrderId
          status
          statusDescription
          createAt
          shippingOrder {
            billing {
              term
              type
            }
          }
          items {
            statusDescription
            orderedQuantity
            expectedQuantity
            billedQuantity
            unitaryNetPrice
            discountPercentage
            product {
              ean
              code
              name
              active
              monitored
              quantity
            }
          }
        }

        tradetoolsResponse
        tradetoolsInvoices
      }
      responseStatus
      invoiceStatus
    }
  }
`;

export const useRetailOrder = (id: String) => {
  return useQuery(RETAIL_ORDER, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
};
