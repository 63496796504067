import React, {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useReactiveVar } from "@apollo/client";

import { CartType, ItemType } from "@types/CartType";
import { CouponsType } from "@types/CouponsType";
import { WholesalerBranchesType } from "@types/WholesalerBranchesType";
import { cartVar, useCart } from "@hooks/useCart";
import { AddItemInput, useAddItem } from "@hooks/useAddItem";
import { useAddSuggestedAnchor } from "@hooks/useAddSuggestedAnchor";
import { AddItemsInput, useAddSuggestedList } from "@hooks/useAddSuggestedList";
import { RemoveItemInput, useRemoveItem } from "@hooks/useRemoveItem";
import { useClearCart } from "@hooks/useClearCart";
import { AddCouponInput, useAddCoupon } from "@hooks/useAddCoupon";
import { removeCouponInput, useRemoveCoupon } from "@hooks/useRemoveCoupon";
import { useClearCoupons } from "@hooks/useClearCoupons";
import {
  ChangeShippingOrderInput,
  useChangeShippingOrder,
} from "@hooks/useChangeShippingOrder";
import { CartInputType, useFindOrCreateCart } from "@hooks/useFindOrCreateCart";
import { AdditionalCustomersInputType, CheckoutInputType, useCheckout } from "@hooks/useCheckout";
import { useAuth } from "./Auth";
import { useCustomer } from "./CustomerContext";
import {
  refactorStatus,
  useGetCustomerCommercialPolicyShippingOrderLazy,
} from "@hooks/useGetCustomerCommercialPolicyShippingOrder";
import { CartErrorEnum } from "@enums/CartErrorEnum";
import { Config } from "@configs/Config";
import { useClearSuggestedList } from "@hooks/useClearSuggestedList";
import { useClearByType } from "@hooks/useClearByType";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { ScheduleInputType, SchedulingInputType, useSchedule } from "@hooks/useSchedule";
import { getSessionStorage } from "@helpers/SessionStorage";
import { PromotionalActionCheckoutType } from "@types/PromotionalActionCheckoutType";
import { SetCustomOrderCodeInput, useSetCustomOrderCode } from "@hooks/useSetCustomOrderCode";
import { useWholesalersStorage } from "@hooks/useWholesalersStorage";
import { useValidateCart } from "@hooks/useValidateCart";

export interface ShoppingCartContextDataProps {
  cart?: CartType;
  loading: boolean;
  empty: boolean;
  addItem(item: ItemType, overwrite?: boolean): Promise<void>;
  addSuggestedAnchor(item: ItemType, overwrite?: boolean): Promise<void>;
  addSuggestedList(
    listName: string,
    listCode: string,
    item: any[],
    overwrite?: boolean
  ): Promise<void>;
  removeItem(code: string): Promise<void>;
  clearCart(): Promise<void>;
  addCoupon(coupon: CouponsType): Promise<void>;
  removeCoupon(coupon: CouponsType): Promise<void>;
  clearCoupons(): Promise<void>;
  changeShippingOrder(shippingOrders: WholesalerBranchesType[]): Promise<void>;
  setCustomOrderCode(customCode: string | null): Promise<void>;
  checkout(additionalCustomers?: AdditionalCustomersInputType[]): Promise<void>
  schedule(schedules: SchedulingInputType[]): Promise<void>
  clearList(listCode: string): Promise<void>;
  clearByType(itemType: ItemTypeEnum[]): Promise<void>;
  validateCart(cartId: string): Promise<void>;
}

export const ShoppingCartContext = createContext<ShoppingCartContextDataProps>(
  {} as ShoppingCartContextDataProps
);

interface ShoppingCartContextProviderProps {
  children: ReactElement;
}

export const ShoppingCartApiProvider: React.FC<
  ShoppingCartContextProviderProps
> = ({ children }) => {
  const cart = useReactiveVar(cartVar);
  const [cartId, setCartId] = useState<string | null>(null);
  const { user, signed } = useAuth();
  const { customerCode, hasCustomer } = useCustomer();
  // const { loading: loadingCart, error } = useCart(cartId ?? '', !cartId);
  const [findOrCreateCartMutation, { loading: loadingFindOrCreateCart }] = useFindOrCreateCart();
  const [addItemMutation, { loading: loadingAddItem }] = useAddItem();
  const [addSuggestedAnchorMutation, { loading: loadingAnchorItem }] =
    useAddSuggestedAnchor();
  const [addSuggestedListMutation, { loading: loadingAddList }] =
    useAddSuggestedList();
  const [removeItemMutation, { loading: loadingRemoveItem }] = useRemoveItem();
  const [clearCartMutation, { loading: loadingClearCart }] = useClearCart();
  const [addCouponMutation, { loading: loadingAddCoupon }] = useAddCoupon();
  const [validateCartMutation, { loading: loadingValidateCart }] = useValidateCart();
  const [removeCouponMutation, { loading: loadingRemoveCoupon }] =
    useRemoveCoupon();
  const [clearCouponsMutation, { loading: loadingClearCoupons }] =
    useClearCoupons();
  const [changeShippingOrderMutation, { loading: loadingChangeShippingOrder }] =
    useChangeShippingOrder();
  const [checkoutMutation, { loading: loadingCheckout }] = useCheckout();
  const [scheduleMutation, { loading: loadingSchedule }] = useSchedule();
  const [query] = useGetCustomerCommercialPolicyShippingOrderLazy();
  const [clearListMutation, { loading: loadingClearList }] =
    useClearSuggestedList();
  const [clearByTypeMutation, { loading: loadingClearByType }] =
    useClearByType();
  const [setCustomCodeMutation, { loading: loadingCustomCodeMutation }] = useSetCustomOrderCode();
  const wholesalersStorage = useWholesalersStorage()
  const numberOfSelectableWholesalers: number = Config.NUMBER_POSSIBLES_WHOLESALER_SELECTED ? Number(Config.NUMBER_POSSIBLES_WHOLESALER_SELECTED) : 6;

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      createCart();
    }

    return () => {
      ignore = true;
    };
  }, [user.getUuid(), user.getNameIndustryStore(), hasCustomer, customerCode, signed])

  useEffect(() => {
    if (
      cart &&
      cart.shippingOrders?.length == 0 &&
      wholesalersStorage.wholesalers.length > 0
    ) {
      (async () => {
        try {
          await changeShippingOrder(wholesalersStorage.wholesalers.splice(0, numberOfSelectableWholesalers));
        } catch (error) {
        }
      })()
    }
  }, [cart?.shippingOrders, wholesalersStorage.wholesalers]);

  const createCart = async (): Promise<string | undefined> => {
    if (
      user.getUuid() &&
      hasCustomer &&
      signed &&
      user.getNameIndustryStore()
    ) {
      let wholesalerList: WholesalerBranchesType[] = [];
      try {
        const { data } = await query();
        wholesalerList = refactorStatus(
          data?.getCustomerCommercialPolicyShippingOrder
        );
      } catch (error) { }

      try {
        const shippingOrderInput = wholesalerList.map((shippingOrder) => {
          return {
            code: shippingOrder.code,
            paymentTerm:
              shippingOrder.wholesaler.paymentTerms?.find(
                (payment) => payment.selected
              )?.code ?? null,
          };
        });

        const shippingOrder = shippingOrderInput.slice(0) ?? [];
        const { data } = await create({
          customer: {
            code: customerCode,
          },
          industry: {
            code: user.getNameIndustryStore(),
          },
          shippingOrder: shippingOrder,
        });

        if (data?.Cart_findOrCreateCart) {
          setCartId(data.Cart_findOrCreateCart.id);
          return data.Cart_findOrCreateCart.id;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const create = async (input: CartInputType) => {
    const option = {
      variables: {
        input,
      },
    };

    return await findOrCreateCartMutation(option);
  };

  const addItem = async (item: ItemType, overwrite?: boolean) => {
    const itemInCart = cart?.items?.find(
      (cartItem) => cartItem.code === item.code
    );
    const amount = item.amount;

    let option = {
      variables: {
        input: {
          cartId: cart?.id,
          product: {
            code: item.code,
            amount: amount + (overwrite ? 0 : itemInCart?.amount ?? 0),
            type: item.type,
            commercialPolicyId: item.commercialPolicyId,
          },
          overwrite: !!overwrite,
        } as AddItemInput,
      },
    };

    try {
      const { errors } = await addItemMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const addSuggestedAnchor = async (item: ItemType, overwrite?: boolean) => {
    const itemInCart = cart?.items?.find(
      (cartItem) => cartItem.code === item.code
    );
    const amount = item.amount;

    let option = {
      variables: {
        input: {
          cartId: cart?.id,
          product: {
            code: item.code,
            amount: amount,
            type: item.type,
            commercialPolicyId: item.commercialPolicyId,
            suggestions: item.suggestions,
          },
          overwrite: !!overwrite,
        } as AddItemInput,
      },
    };

    try {
      const { errors } = await addSuggestedAnchorMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addSuggestedAnchorMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addSuggestedAnchorMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const addSuggestedList = async (
    listName: string,
    listCode: string,
    itens: ItemType[],
    overwrite?: boolean
  ) => {
    const itensInput: any = [];
    itens.map((item: any) => {
      const itemInCart = cart?.items?.find(
        (cartItem) => cartItem.code === item.code
      );
      const amount = item.amount;

      itensInput.push({
        code: item.code,
        amount: amount,
        type: item.type,
        commercialPolicyId: item.commercialPolicyId,
      });
    });

    let option = {
      variables: {
        input: {
          listCode,
          listName,
          cartId: cart?.id,
          products: itensInput,
          overwrite: !!overwrite,
        } as AddItemsInput,
      },
    };

    try {
      const { errors } = await addSuggestedListMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addSuggestedListMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addSuggestedListMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const removeItem = async (code: string) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          productCode: code,
        } as RemoveItemInput,
      },
    };

    try {
      const { errors } = await removeItemMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const clearList = async (listCode: string) => {
    const option = {
      variables: {
        cartId: cart?.id,
        listCode: listCode,
      },
    };

    try {
      const { errors } = await clearListMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const clearByType = async (itemTypes: ItemTypeEnum[]) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          typeItem: itemTypes,
        },
      },
    };

    try {
      const { errors } = await clearByTypeMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const clearCart = async () => {
    const option = {
      variables: {
        cartId: cart?.id,
      },
    };

    await clearCartMutation(option);
  };

  const addCoupon = async (coupon: CouponsType) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          coupon: {
            name: coupon.coupon.name,
          },
        } as AddCouponInput,
      },
    };

    await addCouponMutation(option);
  };

  const validateCart = async (cartId: String) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
        },
      },
    };

    await validateCartMutation(option);
  };

  const removeCoupon = async (coupon: CouponsType) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          coupon: {
            name: coupon.coupon.name,
          },
        } as removeCouponInput,
      },
    };

    await removeCouponMutation(option);
  };

  const clearCoupons = async () => {
    const option = {
      variables: {
        cartId: cart?.id,
      },
    };

    await clearCouponsMutation(option);
  };

  const changeShippingOrder = async (
    shippingOrders: WholesalerBranchesType[]
  ) => {
    const shippingOrderInput = shippingOrders.map((shippingOrder) => {
      return {
        code: shippingOrder.code,
        paymentTerm:
          shippingOrder.wholesaler.paymentTerms?.find(
            (payment) => payment.selected
          )?.code ?? null,
      };
    });

    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          shippingOrder: shippingOrderInput,
        } as ChangeShippingOrderInput,
      },
    };

    try {
      const { errors } = await changeShippingOrderMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const setCustomOrderCode = async (
    customCode: string | null
  ) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          code: customCode,
        } as SetCustomOrderCodeInput,
      },
    };

    try {
      const { errors } = await setCustomCodeMutation(option);

      if (errors?.graphQLErrors) {
        for (let err of errors.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw errors;
          }
        }
      }
    } catch (error: any) {
      if (error?.graphQLErrors) {
        for (let err of error.graphQLErrors) {
          switch (err.extensions.code) {
            case CartErrorEnum.CART_NOT_OPEN:
              const newCartId = await createCart();
              option.variables.input.cartId = newCartId ?? "";
              await addItemMutation(option);
              return;
            case CartErrorEnum.CART_ITEM_ALREADY_IN_ANOTHER_RULE:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MAX_AMOUNT:
              throw error;
            case CartErrorEnum.ITEM_ERROR_MINIMUM_AMOUNT:
              throw error;
          }
        }
      }
      throw new Error("Error inesperado!");
    }
  };

  const getPromotionalAction = async () => {
    const promo = await getSessionStorage('promotionalAction');

    return {
      name: promo?.active ? promo?.name : '',
      origin: promo?.active ? promo?.origin : ''
    }
  }

  const checkout = async (additionalCustomers?: AdditionalCustomersInputType[]) => {

    const promotionalAction: PromotionalActionCheckoutType = await getPromotionalAction();

    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          additionalCustomers,
          promotionalAction: promotionalAction
        } as CheckoutInputType
      },
    };

    await checkoutMutation(option);
    await createCart();
  };


  const schedule = async (schedules: SchedulingInputType[]) => {
    const option = {
      variables: {
        input: {
          cartId: cart?.id,
          schedules
        } as ScheduleInputType
      },
    };

    await scheduleMutation(option);
    await createCart();
  };

  const loading =
    loadingAddItem ||
    loadingAddList ||
    loadingRemoveItem ||
    loadingClearCart ||
    loadingAddCoupon ||
    loadingClearCoupons ||
    loadingRemoveCoupon ||
    loadingChangeShippingOrder ||
    loadingFindOrCreateCart ||
    loadingCheckout ||
    loadingClearList ||
    loadingClearByType ||
    loadingSchedule ||
    loadingCustomCodeMutation;
    loadingValidateCart ||
    loadingAnchorItem;

  const empty = !cart || cart?.items?.length === 0;
  const value = useMemo(
    () => ({
      cart: cart,
      loading,
      empty: empty,
      addItem,
      addSuggestedList,
      removeItem,
      clearCart,
      addCoupon,
      removeCoupon,
      clearCoupons,
      changeShippingOrder,
      checkout,
      createCart,
      clearList,
      clearByType,
      addSuggestedAnchor,
      schedule,
      setCustomOrderCode,
      validateCart
    }),
    [cart, loading, customerCode, user.getNameIndustryStore()]
  );

  return (
    <ShoppingCartContext.Provider value={value}>
      {children}
    </ShoppingCartContext.Provider>
  );
};
