import { Button, ScrollView, useMediaQuery } from "native-base";
import React, { useEffect, useState } from "react";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import { UnexpectedError } from "@components/UnexpectedError";
import { LayoutOrder } from "@components/LayoutOrder";
import { Loading } from "@components/Loading";
import { useFilterWholesalers } from "@hooks/useFilterWholesaler";
import { useSearchWholesalers } from "@hooks/useSearchWholesalers";
import { ChooseWholesaler } from "@screens/ChooseWholesaler";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { RedirectHome } from "@helpers/RedirectHome";
import { WholesalerBranchesType } from "../../types/WholesalerBranchesType";
import { useAuth } from "@contexts/Auth";
import {
  btnSaveStyleProps,
  btnDisabled,
} from "@screens/ChooseWholesaler/styleProps";
import { useRetailOrdersLazy } from "@hooks/useRetailOrders";
import { Config } from "@configs/Config";
import { useWholesalerBranchCart } from "@hooks/useWholesalerBranchCart";
import { useWholesalersStorage } from "@hooks/useWholesalersStorage";

export const WholesalerOrder = () => {
  const { empty, changeShippingOrder } = useShoppingCart();
  const [wholesalerState, setWholesalerState] = useState<
    WholesalerBranchesType[]
  >([]);
  const navigation = useNavigation();
  const redirect = RedirectHome();
  const [isSmallScreen] = useMediaQuery([{ maxWidth: 640 }]);
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const isFocused = useIsFocused();
  const [lastUsedOrderChecked, setLastUsedOrderChecked] = useState(false);
  const [wholesalersResult, setWholesalersResult] =  useState<
  WholesalerBranchesType[]
>([]);
  const numberOfSelectableWholesalers: number =
    Config.NUMBER_POSSIBLES_WHOLESALER_SELECTED
      ? Number(Config.NUMBER_POSSIBLES_WHOLESALER_SELECTED)
      : 6;
  const [retailOrderList, setRetailOrderList] = useState<
    WholesalerBranchesType[]
  >([]);
  const wholesalersStorage = useWholesalersStorage()
  const { searchText, handleSearchWholesalers } = useFilterWholesalers();
  const { wholesalerSearchList, loading, error } = useSearchWholesalers({
    text: searchText,
  });
  const { wholesalers, loading: loadingWholesalers } =
    useWholesalerBranchCart();
  const [queryRetailOrder] = useRetailOrdersLazy(1, 0);

  useEffect(() => {
    if(wholesalersResult.length == 0) {
      setWholesalersResult(wholesalers)
    }
  }, [wholesalers])



  const applyLastUsedOrder = async (check: boolean) => {
    setLastUsedOrderChecked(check);

    if (check) {
      const wholesalerBranchesIDs: string[] = [];
      const {
        data: {
          Retail_retailOrders: { data },
        },
      }: any = await queryRetailOrder();

      if (data && data.length > 0) {
        const wholesalerBranches = data[0].wholesalerBranchesPayment;

        for (let wholesalerBranch of wholesalerBranches) {
          wholesalerBranchesIDs.push(wholesalerBranch.code);
        }

        const wholesalersMap = new Map();
        for (const wholesaler of wholesalerSearchList) {
          wholesalersMap.set(wholesaler.code, wholesaler);
        }

        const filteredList: WholesalerBranchesType[] =
          wholesalerBranchesIDs.map((code) => {
            return wholesalersMap.get(code);
          });

        const lastOrderList: WholesalerBranchesType[] = [];
        let priority = 1;
        let wholesalersFound = 0;
        let emptySpaces = 0;

        for (let i = 0; i < numberOfSelectableWholesalers; i++) {
          if (filteredList[i]?.code) {
            filteredList[i].priority = priority;
            lastOrderList.push(filteredList[i]);
            wholesalersFound++;
            priority++;
          } else {
            lastOrderList.push({
              code: "",
              description: "",
              priority: 0,
              wholesaler: {
                id: 0,
                paymentType: null,
              },
            });
          }
        }

        lastOrderList.map((l) => {
          if(l.priority == 0) {
            emptySpaces ++;
            l.priority = emptySpaces + wholesalersFound;
          }
        })

        lastOrderList.sort((a: WholesalerBranchesType, b: WholesalerBranchesType) =>
        a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0)

        setRetailOrderList(lastOrderList);
      }
    }
  };

  const handleActionButton = async (newPriority: WholesalerBranchesType[]) => {
    await changeShippingOrder(newPriority);
    await wholesalersStorage.update(newPriority);
    navigation.navigate("FinishOrder", { industry: industry });
  };

  const getWholesalers = (): WholesalerBranchesType[] => {

    if (lastUsedOrderChecked) {
      return retailOrderList;
    }

    if (wholesalersResult.length > 0) {
      return wholesalersResult;
    }

    if (wholesalersStorage.wholesalers.length > 0) {
      return wholesalersStorage.wholesalers;
    }

    return [];
  };

  const handleButton = (onPress: () => void, disabled: boolean) => {
    const btnStyleProps = disabled
      ? { ...btnDisabled }
      : { ...btnSaveStyleProps };

    return (
      <Button
        {...btnStyleProps}
        isDisabled={disabled}
        testID="btn-next"
        onPress={onPress}
        _text={{
          fontWeight: "700",
        }}
      >
        Próximo passo
      </Button>
    );
  };

  if (loading || wholesalersStorage.loading || loadingWholesalers) {
    return <Loading />;
  }

  if (empty && isFocused) {
    navigation.navigate(redirect.screen, redirect.params);
  }

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  return (
    <ScrollView flex={1}>
      <LayoutOrder
        title="Distribuidores"
        subtitle="Selecione a ordem e o prazo do envio."
        isMobile={isSmallScreen}
        shopTab={2}
        industryName={industry}
      >
        <ChooseWholesaler
          searchWholesalers={handleSearchWholesalers}
          data={wholesalerSearchList}
          storageValue={getWholesalers()}
          actionButton={handleActionButton}
          handleButton={handleButton}
          clearEnabled={true}
          context={wholesalerState}
          contextFunction={setWholesalerState}
          orderWholesalers={true}
          isPreferredWholesalerScreen={false}
          applyLastUsedOrder={applyLastUsedOrder}
        />
      </LayoutOrder>
    </ScrollView>
  );
};
