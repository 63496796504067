import * as React from 'react';
import { Pressable, IPressableProps } from 'native-base';
import { useLinkProps } from '@react-navigation/native';

type Props = IPressableProps & {
  onPressLink?: () => void;
  to?: any;
  children: React.ReactNode;
  style?: object;
  action?: any;
  disabled?: boolean;
  styleDisabled?: object;
  testID?: string;
}

export function LinkButton({
  onPressLink = () => {},
  to,
  action,
  style,
  disabled = false,
  styleDisabled = {},
  children,
  ...rest
}: Props){
  let {onPress,props}: any = {}

  if(to !== undefined)
  {
    const LinkProps = useLinkProps({ to, action });
    onPress  = {onPress: LinkProps.onPress}
    props = { accessibilityRole: LinkProps.accessibilityRole, href: LinkProps.href}
  }

  if (disabled) {
    return (
      <Pressable style={[style, styleDisabled]} disabled {...rest}>
        {children}
      </Pressable>
    );
  }

  const newOnPress = () => {
    onPressLink();
    return onPress;
  };

  return (
    <Pressable
      _hover={{
        opacity: 0.8,
      }}
      rounded='sm'
      style={style}
      onPress={newOnPress}
      {...props}
      {...rest}
    >
      {children}
    </Pressable>
  );
};
